import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import SiteSelector from 'components/Header/Navigation/SiteSelector';
import CloseIcon from 'components/icons/CloseIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 'var(--width-overlays)',
    height: '100%',
    top: 'calc(var(--height-header) + 4px)',

    [media.min['desktop.sm']]: {
        top: '4px',
    },
});

const SelectButton = styled('button', {
    height: '40px',
    width: '100%',
    padding: '0 14px 0 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid var(--color-bg-button-default)',
    backgroundColor: 'var(--color-bg-button-default)',
    color: 'var(--color-text)',
    borderRadius: '78px',
});

const OverlayBottom = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    padding: '0 8px',
    height: '56px',
    width: '100%',
    zIndex: '2',
    boxShadow: '0px -10px 13px -10px var(--color-bg)',
    bottom: 'calc(var(--height-header) + 8px)',
    borderRadius: '0px 0px 16px 16px',
    backgroundColor: 'var(--color-bg-secondary)',
});
const DesktopView = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
});

const SiteSelectorOverlay = ({ alternateHrefs = [] }) => {
    const [css] = useStyletron();

    const { siteSelectorLinks } = useSiteSelectorQuery();

    const [isSiteSelectorOpen, setIsSiteSelectorOpen] = useState(false);
    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);

    return (
        <Wrapper>
            <SiteSelector
                isFooter
                isOverlay
                alternateHrefs={alternateHrefs}
                setIsSiteSelectorOpen={setIsSiteSelectorOpen}
            />
            <OverlayBottom>
                <DesktopView>
                    <SelectButton
                        onClick={() => {
                            setIsSiteSelectorOpen(!isSiteSelectorOpen);
                            overlay.close('site-selector');
                        }}
                    >
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            })}
                        >
                            <img
                                className={css({
                                    marginRight: '16px',
                                    borderRadius: '60px',
                                    width: '16px',
                                    height: '16px',
                                })}
                                src={currentSite?.icon}
                            />
                            <Paragraph fontKeys="Miscellaneous/16_100_-1">{currentSite?.label}</Paragraph>
                        </div>
                        <CloseIcon width="14px" />
                    </SelectButton>
                </DesktopView>
            </OverlayBottom>
        </Wrapper>
    );
};

SiteSelectorOverlay.propTypes = {
    alternateHrefs: PropTypes.array,
};

export default SiteSelectorOverlay;
